import { callAppFunc } from './jsbridge';

const setMetaLogEvent = (title, param) => {
  console.log('title', title);
  console.log('param', param);

  if (typeof window !== 'undefined') {
    //app 版本
    callAppFunc('appMetaLogEvent', { title_name: title, parameters: param });
  }
};

const getEffectivePrice = (element) => {
  return element.price === element.salePrice
    ? element.price
    : element.salePrice;
};

//商品內頁
const setMetaViewItem = (element, id) => {
  const { currency, item_name, main_category } = element;
  const price = getEffectivePrice(element);
  setMetaLogEvent('view_item', {
    CONTENT_TYPE: 'product',
    CONTENT_ID: id,
    CURRENCY: currency,
    valueToSum: parseInt(price),
    CONTENT: {
      item_id: id,
      item_name: item_name,
      item_category: main_category,
    },
  });
};

//加入購物車
const setMetaAddToCart = (element) => {
  const { currency, id, item_name, main_category, count } = element;
  const price = getEffectivePrice(element);
  setMetaLogEvent('add_to_cart', {
    CURRENCY: currency,
    valueToSum: parseInt(price) * parseInt(count),
    CONTENT_ID: id,
    CONTENT_TYPE: 'product',
    CONTENT: {
      item_id: id,
      item_name: item_name,
      item_category: main_category,
    },
  });
};

//進入購物車頁面
const setMetaViewCart = (res) => {
  const its = [];
  for (const element of res.productItems) {
    const obj = {
      item_id: element.productId,
      item_name: element.itemName,
      item_category: element.main_category,
      item_variant: element.itemSpec,
      price: parseInt(element.basePrice),
      quantity: element.qty,
    };
    its.push(obj);
  }

  setMetaLogEvent('view_cart', {
    currency: 'TWD',
    value: res.productTotal,
    items: its,
  });
};

const setMetaBeginCheckout = (cartList, showOrderTotal) => {
  const its = [];
  var totalCount = 0;
  var idString = '{';
  for (const element of cartList) {
    const obj = {
      item_id: element.productId,
      item_name: element.itemName,
      item_category: element.main_category,
    };
    totalCount += element.qty;
    idString = idString + `"id":"${element.productId}",`;
    its.push(obj);
  }
  if (idString.length > 1) {
    idString = idString.substring(0, idString.length - 1);
    idString = idString + '}';
  }

  setMetaLogEvent('begin_checkout', {
    CURRENCY: 'TWD',
    valueToSum: showOrderTotal,
    CONTENT: its,
    CONTENT_TYPE: 'product_group',
    NUM_ITEMS: totalCount,
    PAYMENT_INFO_AVAILABLE: 1,
    CONTENT_ID: idString,
  });
};

const setMetaPurchase = (data) => {
  const { order_no, total_amount } = data;
  const its = [];
  var totalCount = 0;
  for (const element of data.products) {
    const obj = {
      item_id: element.sfcc_productnumber,
      item_name: element.sfcc_itemname,
      item_category: element.main_category,
    };
    its.push(obj);
    totalCount += element.qty;
  }

  setMetaLogEvent('purchase', {
    CONTENT_ID: order_no,
    transaction_id: order_no,
    CURRENCY: 'TWD',
    valueToSum: total_amount,
    CONTENT_TYPE: 'product_group',
    PAYMENT_INFO_AVAILABLE: 1,
    CONTENT: its,
    NUM_ITEMS: totalCount,
  });
};

export {
  setMetaLogEvent,
  setMetaViewItem,
  setMetaAddToCart,
  setMetaViewCart,
  setMetaBeginCheckout,
  setMetaPurchase,
};
