import React from 'react';
import parse from 'html-react-parser';

const SpecInfo = ({ data = [] }) => {
  return (
    <>
      <table>
        {data.map((row) => (
          <tbody key={`${row.id}-${row}`}>
            <tr>
              <th>{row.title}</th>
              <td>{parse(row.content)}</td>
            </tr>
          </tbody>
        ))}
      </table>

      <style jsx>
        {`
          table {
            border: 1px solid #e4e4e4;
            border-collapse: collapse;
            width: 100%;
            text-align: left;

            tr {
              border: 1px solid #e4e4e4;
              th {
                padding: 15px 10px;
                border: 1px solid #e4e4e4;
                background-color: #eeeeee;
              }
              td {
                padding: 15px 10px;
                border: 1px solid #e4e4e4;
              }
            }
          }
        `}
      </style>
    </>
  );
};

export default SpecInfo;
