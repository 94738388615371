import React, { useState } from 'react';

import IconArrowRight from '../../images/icons/angle-arrow-right-black-s.svg';
import IconArrowDown from '../../images/icons/angle-arrow-down-black-s.svg';

const CollapseCard = ({ title = '標題', children, expand }) => {
  const [isExpanded, setIsExpanded] = useState(expand);

  return (
    <>
      <div className={`collapse-container ${isExpanded ? 'expanded' : ''}`}>
        <div className="header" onClick={() => setIsExpanded(!isExpanded)}>
          <h2 className="title">{title}</h2>
          <img src={IconArrowRight} alt="arrow" className="arrow" />
        </div>
        <div className="content">{children}</div>
      </div>

      <style jsx>
        {`
          .collapse-container {
            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #e4e4e4;
              padding: 15px 0;

              .title {
                font-family: 'Noto Sans TC';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 26px;
                color: #333333;
              }
            }

            .content {
              padding: 0px 5px;
              overflow: hidden;
              max-height: 0;
              transition: 300ms ease-out;
              line-height: 0;
              opacity: 0;

              * {
                max-height: 0;
                transition: 300ms ease-out;
                overflow: hidden;
                line-height: 0;
                opacity: 0;
                height: 0;
              }
            }

            &.expanded {
              .header {
                .arrow {
                  transform: rotate(90deg);
                }
              }

              .content {
                padding: 10px 5px;
                max-height: 9000px;
                line-height: 1.4;
                opacity: 1;

                * {
                  max-height: 9000px;
                  line-height: 1.4;
                  opacity: 1;
                  height: auto;
                }
              }
            }
          }
        `}
      </style>
    </>
  );
};

export default CollapseCard;
