import React, { useState, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

SwiperCore.use([Autoplay, Pagination, Navigation]);

const Watermark = ({ imageUrl, altText, className }) => (
  <div className={`watermark ${className}`}>
    <img alt={altText} src={imageUrl} />
  </div>
);

const SwiperLightbox = ({ product, images, open }) => {
  const { openLightBox } = open;
  const imageData = images;

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const changePhotoIndex = (status) => {
    setPhotoIndex(status);
  };

  const changeIsOpen = (value) => {
    if (value) {
      mySwiper.current.swiper.autoplay.stop();
    } else {
      mySwiper.current.swiper.autoplay.start();
    }
    setIsOpen(value);
    openLightBox(value);
    setPhotoIndex(mySwiper.current.swiper.realIndex);
  };

  useEffect(() => {
    const pagination = document.querySelector('.swiper-pagination-fraction');
    if (pagination.innerHTML.includes('/')) {
      pagination.innerHTML = `<span class="swiper-pagination-current">1</span><span style="font-size: 10px;"> | </span><span class="swiper-pagination-total">${imageData.length}</span>`;
    }

    if (imageData.length === 1) {
      mySwiper.current.swiper.autoplay.stop();
    }
  }, []);

  const mySwiper = useRef();
  const myLightbox = useRef();

  return (
    <>
      <div className="swiper-lightbox">
        <div id={'myLightbox'}>
          {isOpen && (
            <Lightbox
              mainSrc={imageData[photoIndex].link}
              nextSrc={imageData[(photoIndex + 1) % imageData.length].link}
              prevSrc={
                imageData[
                  (photoIndex + imageData.length - 1) % imageData.length
                ].link
              }
              onCloseRequest={() => changeIsOpen(false)}
              onMovePrevRequest={() =>
                changePhotoIndex(
                  (photoIndex + imageData.length - 1) % imageData.length
                )
              }
              onMoveNextRequest={() =>
                changePhotoIndex((photoIndex + 1) % imageData.length)
              }
              imagePadding={0}
              ref={myLightbox}
              nextLabel={''}
            />
          )}
        </div>

        {isOpen && (
          <div className="lightbox-pagination">
            <span>{photoIndex + 1}</span>
            <span> | </span>
            <span>{imageData.length}</span>
          </div>
        )}

        <div className={`mySwiper ${isOpen ? 'open-lightbox' : ''}`}>
          <Swiper
            init={true}
            slidesPerView={1}
            initialSlide={0}
            loop={true}
            spaceBetween={0}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              type: 'fraction',
            }}
            navigation={false}
            ref={mySwiper}
          >
            {imageData &&
              imageData.map((image, index) => (
                <SwiperSlide key={index}>
                  <div
                    onClick={() => changeIsOpen(true)}
                    className="slide-image"
                  >
                    <div style={{ position: 'relative' }}>
                      {product.watermark && index === 0 && (
                        <>
                          {product.watermark.coverVisible &&
                            product.watermark.coverImageUrl && (
                              <Watermark
                                imageUrl={product.watermark.coverImageUrl}
                                altText={
                                  product.brand
                                    ? product.brand
                                    : product.productName
                                }
                                className=" watermark-covert"
                              />
                            )}

                          {product.watermark.brandVisible &&
                            product.watermark.brandImageUrl && (
                              <Watermark
                                imageUrl={product.watermark.brandImageUrl}
                                altText={
                                  product.brand
                                    ? product.brand
                                    : product.productName
                                }
                                className=" watermark-lt watermark-homepage watermark-menu"
                              />
                            )}

                          {product.watermark.discountVisible &&
                            product.watermark.discountImageUrl && (
                              <Watermark
                                imageUrl={product.watermark.discountImageUrl}
                                altText={product.watermark.discountValue}
                                className=" watermark-rt watermark-homepage watermark-menu"
                              />
                            )}

                          {product.watermark.calloutMsgVisible &&
                            product.watermark.calloutMsgImageUrl && (
                              <Watermark
                                imageUrl={product.watermark.calloutMsgImageUrl}
                                altText={product.watermark.productName}
                                className={` watermark-fullwidth watermark-lb ${
                                  product.watermark.markVisible
                                    ? 'watermark-menu-callout'
                                    : 'watermark-menu-callout-full'
                                }`}
                              />
                            )}

                          {product.watermark.markVisible &&
                            product.watermark.markImageUrl && (
                              <Watermark
                                imageUrl={product.watermark.markImageUrl}
                                altText={
                                  product.brand
                                    ? product.brand
                                    : product.productName
                                }
                                className=" watermark-rb watermark-homepage watermark-menu"
                              />
                            )}
                        </>
                      )}
                      <img
                        src={image.link}
                        alt={image.alt}
                        title={image.title}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>

      <style jsx>{`
        .swiper-lightbox {
          position: relative;
        }
        .slide-image {
          width: 100%;
          height: 300px;
          display: flex;
          justify-content: center;
          img {
            width: auto;
            height: 300px;
          }
        }
        .lightbox-pagination {
          position: fixed;
          color: #ffffff;
          z-index: 1001;
          top: calc(50vh + 50vw + 10px);
          left: calc(50vw - 30px);
          span:nth-child(2) {
            font-size: 14px;
          }
        }
        :global(
            .ril-next-button,
            .ril-prev-button,
            .ril-zoom-in,
            .ril-zoom-out
          ) {
          display: none;
        }
        :global(.ril-close) {
          position: fixed;
          left: 10px;
          top: 10px;
        }
        :global(.ril__image) {
          top: -10vh;
        }
        .mySwiper {
          position: relative;
          :global(.swiper-pagination-fraction) {
            min-width: 35px;
            max-width: 40px;
            height: 20px;
            font-size: 12px;
            line-height: 17px;
            background: #c4c4c4;
            color: #ffffff;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 0 5px;
            position: absolute;
            left: calc(100vw - 71px);
            bottom: 0;
            span {
              color: red;
            }
          }
        }
      `}</style>
    </>
  );
};

export default SwiperLightbox;
