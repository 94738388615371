import { callAppFunc } from './jsbridge';

const setAppsFlyerEvent = (title, param) => {
  console.log('title', title);
  console.log('param', param);

  if (typeof window !== 'undefined') {
    //app 版本
    callAppFunc('appAppsFlyerLogEvent', {
      title_name: title,
      parameters: param,
    });
  }
};

const getEffectivePrice = (element) => {
  return element.price === element.salePrice
    ? element.price
    : element.salePrice;
};

// 3.搜尋
const setAppsFlyerSearch = (searchWord, res) => {
  // 將res.items.id轉為陣列
  const idArray = res.rows.map((item) => item.id);

  setAppsFlyerEvent('af_search', {
    af_search_string: searchWord,
    af_content_list: idArray,
  });
};

// 4.商品內頁
const setAppsFlyerContentView = (res) => {
  const { currency, id, ean, main_category } = res;
  const price = getEffectivePrice(res);
  setAppsFlyerEvent('af_content_view', {
    af_content_id: id,
    af_content_type: main_category,
    af_currency: currency,
    af_price: parseInt(price),
    af_content: ean,
  });
};

// 5.PLP
const setAppsFlyerListView = (categoryId, res) => {
  // 將res.items.id轉為陣列
  const idArray = res.rows.map((item) => item.id);

  setAppsFlyerEvent('af_list_view', {
    af_content_type: categoryId,
    af_content_list: idArray,
  });
};

// 6. 加入我的最愛（QC未使用）
const setAppsFlyerAddToWishList = (res) => {
  setAppsFlyerEvent('af_add_to_wishlist', {
    af_price: 0,
    af_content_id: '',
    af_content_type: '',
    af_currency: '',
  });
};

// 7. 加入購物車
const setAppsFlyerAddToCart = (res) => {
  const { currency, id, main_category, qty, count } = res;
  const price = getEffectivePrice(res);
  setAppsFlyerEvent('af_add_to_cart', {
    af_price: parseInt(price),
    af_content: id,
    af_content_id: id,
    af_content_type: main_category,
    af_currency: currency,
    af_quantity: qty || count,
  });
};

// 8. 開始結帳
const setAppsFlyerInitiateCheckout = (list, totalPrice = 0) => {
  // 取得list中productId與main_category的陣列
  const idArray = list.map((item) => ({
    id: item.productId,
    main_category: item.main_category,
  }));

  setAppsFlyerEvent('af_initiated_checkout', {
    af_price: parseInt(totalPrice),
    af_content_id: idArray.map((item) => item.id),
    af_content_type: idArray.map((item) => item.main_category),
    af_currency: 'TWD',
    af_quantity: list.length,
  });
};

// 9. 訂單成立
const setAppsFlyerPurchase = (res) => {
  const idArray = res.products.map((item) => ({
    id: item.productnumber,
    main_category: item.main_category,
  }));
  const { order_no, amount } = res;
  setAppsFlyerEvent('af_purchase', {
    af_revenue: parseInt(amount),
    af_price: parseInt(amount),
    af_content: idArray.map((item) => item.id),
    af_content_type: idArray.map((item) => item.main_category),
    af_currency: 'TWD',
    af_quantity: idArray.length,
    af_order_id: order_no,
    af_receipt_id: order_no,
  });
};

// 10. 第一次訂單成立
const setAppsFlyerFirstPurchase = (res) => {
  const idArray = res.products.map((item) => ({
    id: item.productnumber,
    main_category: item.main_category,
  }));
  const { order_no, amount } = res;
  setAppsFlyerEvent('first_purchase', {
    af_revenue: parseInt(amount),
    af_price: parseInt(amount),
    af_content: idArray.map((item) => item.id),
    af_content_type: idArray.map((item) => item.main_category),
    af_currency: 'TWD',
    af_quantity: idArray.length,
    af_order_id: order_no,
    af_receipt_id: order_no,
  });
};

// 11. 購物車商品移除
const setAppsFlyerRemoveFromCart = (res) => {
  const { id, productId, main_category } = res;
  setAppsFlyerEvent('af_remove_from_cart', {
    af_content_id: productId || id,
    af_content_type: main_category,
  });
};

// 12. 進入購物車頁面
const setAppsFlyerViewCart = (res) => {
  const its = [];
  for (const element of res.productItems) {
    const obj = {
      item_id: element.productId,
      item_name: element.itemName,
      currency: 'TWD',
      item_category: element.main_category,
      item_variant: element.itemSpec,
      price: parseInt(element.basePrice),
      quantity: element.qty,
    };
    its.push(obj);
  }
  setAppsFlyerEvent('view_cart', {
    af_curreny: 'TWD',
    af_revenue: res.productTotal,
    items: its,
  });
};

export {
  setAppsFlyerSearch,
  setAppsFlyerContentView,
  setAppsFlyerListView,
  setAppsFlyerAddToWishList,
  setAppsFlyerAddToCart,
  setAppsFlyerInitiateCheckout,
  setAppsFlyerPurchase,
  setAppsFlyerFirstPurchase,
  setAppsFlyerRemoveFromCart,
  setAppsFlyerViewCart,
};
